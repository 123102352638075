import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n'

import reportWebVitals from './reportWebVitals';

import './styles/globals.css';
import { lazy } from 'react';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage').then(({ NotFoundPage }) => ({ default: NotFoundPage })),);
const Home = lazy(() => import('./pages/Home').then(({ Home }) => ({ default: Home })));
const HomePage = lazy(() => import('./pages/home-page').then(({ HomePage }) => ({ default: HomePage })));
const Contacts = lazy(() => import('./pages/contacts').then(({ Contacts }) => ({ default: Contacts })));
const Services = lazy(() => import('./pages/services').then(({ Services }) => ({ default: Services })));
const Portfolio = lazy(() => import('./pages/portfolio').then(({ Portfolio }) => ({ default: Portfolio })));
const About = lazy(() => import('./pages/about').then(({ About }) => ({ default: About })));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions').then(({ TermsAndConditions }) => ({ default: TermsAndConditions })));
const DefaultService = lazy(() => import('./pages/default-service').then(({ DefaultService }) => ({ default: DefaultService })));
const WeddingHub = lazy(() => import('./pages/wedding-hub').then(({ WeddingHub }) => ({ default: WeddingHub })));
const SeatingPlanner = lazy(() => import('./pages/seating-planner').then(({ SeatingPlanner }) => ({ default: SeatingPlanner })));
const Baptism = lazy(() => import('./pages/baptism').then(({ Baptism }) => ({ default: Baptism })));
const PresentationSaveTheDate = lazy(() => import('./pages/presentation/save-the-date').then(({ PresentationSaveTheDate }) => ({ default: PresentationSaveTheDate })));
const PresentationWeddingHub = lazy(() => import('./pages/presentation/wedding-hub').then(({ PresentationWeddingHub }) => ({ default: PresentationWeddingHub })));
const PresentationSeatingPlanner = lazy(() => import('./pages/presentation/seating-planner').then(({ PresentationSeatingPlanner }) => ({ default: PresentationSeatingPlanner })));

// Initialize React Ga with your tracking ID
ReactGA.initialize('G-LC7KKF93J8');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <Routes>
      <Route path="/" Component={HomePage} />
      <Route path="/home" Component={Home} />
      <Route path="/contacts" Component={Contacts} />
      <Route path="/services" Component={Services} />
      <Route path="/about" Component={About} />
      <Route path="/portfolio" Component={Portfolio} />
      <Route path="/terms-and-conditions" Component={TermsAndConditions} />

      {/* Route for Wedding Detail page */}
      <Route path="/:event_id" Component={DefaultService} />
      <Route path="/:event_id/wedding-hub/" Component={WeddingHub} />
      <Route path="/:event_id/seating-planner/" Component={SeatingPlanner} />
      <Route path="/:event_id/baptism/" Component={Baptism} />

      {/* Route for Presentation pages */}
      <Route path="/presentation/wedding-hub" Component={PresentationWeddingHub} />
      <Route path="/presentation/save-the-date" Component={PresentationSaveTheDate} />
      <Route path="/presentation/seating-planner/:event_id" Component={PresentationSeatingPlanner} />

      {/* Route for 404 Not Found page */}
      <Route path='*' Component={NotFoundPage} />
    </Routes>
  </Router>
);

reportWebVitals();
